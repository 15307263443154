// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../common/img/suggestions.svg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../common/img/edit.svg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../common/img/colors.svg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../common/img/regular-tag.svg");
var ___HTML_LOADER_IMPORT_4___ = require("../../../../common/img/delete.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var code = "<drop-down position=\"bottom left\" class=\"tags\"> <div class=\"menu\"> <div class=\"menu__item\" @click=\"suggestions\"> <span class=\"menu__item--icon\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> </span> <span class=\"menu__item--text\">{{ 'Show alternatives' | say }}</span> </div> <div class=\"menu__item\" @click=\"change\"> <span class=\"menu__item--icon\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> </span> <span class=\"menu__item--text\">{{ 'Edit' | say }}</span> </div> <div class=\"menu__item\"> <span class=\"menu__item--icon\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> </span> <span class=\"menu__item--text\">{{ 'Change Color' | say }}</span> <span class=\"menu__item--caret\"> <i class=\"fa fa-caret-right\"></i> </span> <tag-colors :tag=\"tag\" :story-id=\"storyId\"></tag-colors> </div> <div class=\"menu__item\"> <span class=\"menu__item--icon\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"> </span> <span class=\"menu__item--text\">{{ 'Change Tag Type' | say }}</span> <span class=\"menu__item--caret\"> <i class=\"fa fa-caret-right\"></i> </span> <tag-types :tag=\"tag\" :passage=\"passage\" :story-id=\"storyId\"></tag-types> </div> <div class=\"menu__item\" @click=\"remove\"> <span class=\"menu__item--icon\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\"> </span> <span class=\"menu__item--text\">{{ 'Delete' | say }}</span> </div> </div> </drop-down>";
// Exports
module.exports = code;
// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../common/img/new-window-icon.svg");
var ___HTML_LOADER_IMPORT_1___ = require("../../common/img/logo-chatbottery.png");
var ___HTML_LOADER_IMPORT_2___ = require("../../common/img/plus-icon.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div class=\"infoContent\"> <div class=\"infoContent--hero\"> <header class=\"infoContent--header\"> <div class=\"infoContent--description\"> {{ 'Meet our award winning chatbot software. Made with' | say }} <i class=\"fa fa-heart\" aria-hidden=\"true\"></i> + <a href=\"https://twinery.org/\" target=\"_blank\">Twinery</a>. </div> <button class=\"infoContent--nav-item\" @click=\"openRedirect('https://chatbottery.com/')\"> <div class=\"infoContent--text\">{{ 'About us' | say }}</div> <div class=\"infoContent--redirect\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> </button> </header> <div class=\"infoContent--logo\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/> </div> <div class=\"infoContent--version\"> Editor version <span>{{ appInfo.version }}</span> </div> <footer class=\"infoContent--footer\"> <button class=\"infoContent--new-chatbot\" :title=\"'New chatbot' | say\" @click=\"createStoryPrompt\"> <span>{{ 'Create a Chatbot' | say }}</span> <img class=\"icon\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"/> </button> </footer> </div> <wordpress-news></wordpress-news> </div> ";
// Exports
module.exports = code;
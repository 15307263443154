// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../common/img/regular-tag.svg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../common/img/tick.svg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../common/img/main-tag.svg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../../common/img/group-tag.svg");
var ___HTML_LOADER_IMPORT_4___ = require("../../../../../common/img/suggestion-tag.svg");
var ___HTML_LOADER_IMPORT_5___ = require("../../../../../common/img/conditional-tag.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var code = "<drop-down position=\"right top\" open-on=\"hover\"> <div class=\"menu tag-types\"> <div class=\"menu__item\" @click=\"setType('')\"> <img class=\"tag-type--image\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <div class=\"tag-type--text\">{{ 'Regular' | say }}</div> <div class=\"tag-type--check\"> <img v-show=\"!type\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> </div> </div> <div class=\"menu__item\" @click=\"setType('#')\"> <img class=\"tag-type--image\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> <div class=\"tag-type--text\">{{ 'Main-Tag' | say }}</div> <div class=\"tag-type--check\"> <img v-show=\"type === '#'\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> </div> </div> <div class=\"menu__item\" @click=\"setType('@')\"> <img class=\"tag-type--image\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"> <div class=\"tag-type--text\">{{ 'Group' | say }}</div> <div class=\"tag-type--check\"> <img v-show=\"type === '@'\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> </div> </div> <div class=\"menu__item\" @click=\"setType('/')\"> <img class=\"tag-type--image\" src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\"> <div class=\"tag-type--text\">{{ 'Suggestion' | say }}</div> <div class=\"tag-type--check\"> <img v-show=\"type === '/'\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> </div> </div> <div class=\"menu__item\" @click=\"setType('%')\"> <img class=\"tag-type--image\" src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\"> <div class=\"tag-type--text\">{{ 'Conditional' | say }}</div> <div class=\"tag-type--check\"> <img v-show=\"type === '%'\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> </div> </div> </div> </drop-down>";
// Exports
module.exports = code;